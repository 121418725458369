@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
.wallet-detail-modal {
  width: 50% !important;
}

.headTitle {
  margin-top: 1.6rem;
  margin-bottom: 1.2rem;
}

.ant-btn-primary:hover {
  background-color: #33464e !important;
}

.btn-edit {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fafafa;
  padding-left: 13px;
  padding-right: 5px;
}
.btn-view {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #fafafa;
  padding-left: 4px;
  padding-right: 13px;
}
.btn-center {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background-color: #fafafa;
  padding-left: 8px;
  padding-right: 8px;
}
.btn-action {
  background-color: #fafafa;
  padding-left: 13px;
  padding-right: 13px;
}
.btn-edit:hover, .btn-view:hover, .btn-action:hover, .btn-center:hover {
  border-color: #d9d9d9 !important;
  background-color: #fafafa !important;

}


.custom-modal .ant-modal-header {
  border-bottom: 2px solid #4caf50; /* Add border below the header */
  padding: 0 !important;
}

.custom-modal .ant-modal-body {
  border: 1px solid #d9d9d9; /* Add border around the body */
  padding: 20px; /* Optional: Custom padding */
}
.grid-container {
  display: grid;
  place-items: center; /* Center both horizontally and vertically */
  height: 100vh; /* Full height of the viewport */
}

.btn-save {
  @apply bg-primaryButton font-semibold px-7;
}

.btn-cancel {
  @apply bg-cancelButton text-textGray font-semibold px-7;
}