.ant-menu {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff !important;
  border: none !important;
}
.ant-menu-item {
  font-size: 16px;
  color: #808080 !important;
  margin-bottom: 10px !important;
}
.ant-menu-item-selected {
  background-color: #33464e !important;
  color: #ffffff !important;
}
.ant-menu-submenu {
  font-size: 16px;
  color: #808080 !important;
  padding-bottom: 2px;
}

.ant-menu-title-content {
  color: #808080 !important;
}

.ant-menu-submenu-arrow {
  color: #808080 !important;
}

.ant-menu-item-icon {
  color: #808080 !important;
}

.menu-title {
  background-color: #ffffff;
  width: 207px;
  padding: 6px 0 2px 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.upgrade-desc {
  color: #808080;
  font-size: 13px;
}

.active-outlet {
  background-color: #f0f5ff; /* Light blue */
  border-radius: 4px;
  font-weight: bold;
  color: #1890ff; /* Primary color */
}

.active-outlet:hover {
  background-color: #d6e4ff; /* Slightly darker blue on hover */
}